<script setup lang="ts">
import type DataObject from 'o365.modules.DataObject.ts';
import type FilterObject from 'o365.modules.FilterObject.ts';

const props = withDefaults(defineProps<{
    dataObject?: DataObject,
    filterObject?: FilterObject,
    containerClass?: string,
    stringLabelClass?: string,
    applyButtonClass?: string,
    clearButtonClass?: string,
}>(), {
    containerClass: 'd-flex align-items-baseline',
    stringLabelClass: 'text-truncate',
    applyButtonClass: 'btn btn-outline-primary btn-sm px-1 pt-0 ms-2',
    clearButtonClass: 'btn btn-sm btn-link px-1 pt-0 ms-2'
});

const filterObject = props.filterObject ?? props.dataObject?.filterObject;

const clear = ()=>{
    filterObject.clear();
}
const apply = ()=>{
    filterObject.apply();
}
</script>
<template>
    <div class="filterstring" :class="containerClass" v-bind="$attrs" >
            <label :class="stringLabelClass" :title="filterObject.prettyString" >{{filterObject.prettyString}}</label>
         
            <button v-if="filterObject.hasChanges && (filterObject.filterString || (filterObject.hasAppliedItems && filterObject.appliedFilterString !== ''))" @click="apply" 
                :class="applyButtonClass">{{$t('Apply')}}</button>
            
            <slot v-if="filterObject.filterString" name="clear" :clear="clear">
                <button :class="clearButtonClass" @click="clear">{{$t('Clear')}}</button>
            </slot>
            <!-- <button v-if="filterObject.filterString" class="btn btn-sm btn-link px-1 pt-0 ms-2" @click="clear">{{$t('Save As')}}</button> -->
    </div>
</template>